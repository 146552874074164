<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex flex-row align-center justify-start">
        <v-icon class="mr-3">mdi-credit-card-outline</v-icon>
        <h4 class="mb-0">ชำระเงิน</h4>
        <span class="ml-3 title-customer-data">รายการใบเสร็จเลขที่ {{bill_no}}</span>
      </div>

      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="6">
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="12">
                <h5>
                  <v-icon large>mdi-account-circle-outline</v-icon>
                  ข้อมูลลูกค้า
                </h5>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="8">
                    <v-row>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">รหัสลูกค้า</span>
                        <span class="label-customer-data">{{customerData.code || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">กลุ่มลูกค้า</span>
                        <span class="label-customer-data">{{customerData.customer_type || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">ชื่อ - นามสกุล</span>
                        <span class="label-customer-data">{{customerData.idcardnumber || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">เลขบัตรประชาชน</span>
                        <span class="label-customer-data">{{customerData.idcardnumber || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">เพศ</span>
                        <span class="label-customer-data">{{customerData.gender === 'male' ? 'ผู้ชาย' : customerData.gender === 'female' ? 'ผู้หญิง' : 'ไม่ระบุ'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">ที่อยู่</span>
                        <span class="label-customer-data">{{customerData.address || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">วันเกิด</span>
                        <span class="label-customer-data">{{moment(customerData.birthdate).add(543, 'year').format('DD / MM / YYYY') || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">เบอร์โทรศัพท์</span>
                        <span class="label-customer-data">{{customerData.phonenumber || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">ID Line</span>
                        <span class="label-customer-data">{{customerData.lineid || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">Facebook</span>
                        <span class="label-customer-data">{{customerData.facebook || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">E-mail</span>
                        <span class="label-customer-data">{{customerData.email || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">กรุ๊ปเลือด</span>
                        <span class="label-customer-data">{{customerData.bloodgroup || '-'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">แพ้ยา</span>
                        <span class="label-customer-data">{{customerData.allergy || 'ไม่มี'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">โรคประจำตัว</span>
                        <span class="label-customer-data">{{customerData.congenital || 'ไม่มี'}}</span>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6" class="d-flex flex-column">
                        <span class="title-customer-data">อื่นๆ</span>
                        <span class="label-customer-data">{{customerData.otherallergy || 'ไม่มี'}}</span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="12">
                <h5>
                  <v-icon large>mdi-stethoscope</v-icon>
                  การรักษา
                </h5>
                <v-data-table
                  :headers="headersTreatment"
                  :items="dataTreatment"
                  :loading="loaddataTreatment"
                  loading-text="กำลังโหลด..."
                  :options="{
                    'itemsPerPage': itemsPerPageTreatment
                  }"
                >
                  <template v-slot:item.treatment_name="{ item }">
                    <div class="text-start">{{item.treatment_name}}</div>
                  </template>
                  <template v-slot:item.treatment_costprice="{ item }">
                    <div class="text-end">{{formatThaiBaht(item.treatment_costprice)}}</div>
                  </template>
                  <template v-slot:no-data>
                    <v-btn
                      color="primary"
                      @click="initData"
                    >
                      รีเซ็ต
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="12" md="12" lg="6">
            <h5>
              <v-icon large>mdi-credit-card-outline</v-icon>
              สรุปค่าใช้จ่าย
            </h5>
            <v-row>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data text-decoration-underline">ยอดรวม</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(totalPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">ค่ามัดจำ</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(prepaidPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">ส่วนลด</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(discountPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">มูลค่าหลังหักส่วนลด</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(afterDiscountPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">ภาษีมูลค่าเพิ่ม</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(vatPrice.price)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data text-decoration-underline">รวมทั้งสิ้น</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${formatThaiBaht(netPrice)} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" class="d-flex flex-row align-center">
                <div class="d-flex flex-column">
                  <span class="label-customer-data">จำนวนแต้ม {{totalPoint.value}} แต้ม</span>
                  <span class="title-customer-data">(1 แต้ม: 1 บาท)</span>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${totalPoint.use} แต้ม`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" class="d-flex flex-row align-center">
                <span class="label-customer-data">คูปองส่วนลด {{totalDiscountPoint.value}} บาท</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${totalDiscountPoint.use} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data text-decoration-underline">ยอดรวมที่ต้องชำระ</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${totalPayPriceFunc()} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">สถานะการจ่ายเงิน:</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{paramsData.bill_paidstatus || '-'}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" class="d-flex align-center">
                <span class="label-customer-data">ช่องทางการชำระ:</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{paramsData.bill_paidby.type === 'credit' ? 'บัตรเครดิต' : 'เงินสด'}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" class="d-flex flex-column justify-center">
                <span class="label-customer-data">ประเภทการชำระเงิน:</span>
                <span class="title-customer-data">*มีเงื่อนไข</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{paramsData.bill_type}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8" class="d-flex align-center">
                <span class="label-customer-data">งวดที่ 1: (ยอดชำระครั้งนี้)</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{payInThisTime}}</span>
              </v-col>
              <v-col v-if="paramsData.bill_paidby.type === 'credit'" cols="12" sm="12" md="12" lg="8" class="d-flex flex-column justify-center">
                <span class="label-customer-data">ค่าธรรมเนียมในการรูดบัตร</span>
                <span class="title-customer-data">*มีเงื่อนไข</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{calVatPayInThisTime()}} บาท</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">ยอดรวมชำระ:</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${calPayInThisTime()} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">ยอดค้างชำระ:</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{`${calRemainPaid()} บาท`}}</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="8">
                <span class="label-customer-data">พนักงาน:</span>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="4">
                <span class="label-customer-data">{{paramsData.bill_createdby.name || '-'}}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          @click="addListFunc()"
        >
          <v-icon class="mr-3">mdi-printer</v-icon>
          ใบเสร็จ
        </v-btn>
        <v-btn
          color="blue white--text"
          @click="addListFunc()"
        >
          <v-icon class="mr-3">mdi-printer</v-icon>
          ใบกำกับภาษี
        </v-btn>
        <v-btn
          color="secondary"
          @click="addListFunc()"
        >
          <v-icon class="mr-3">mdi-printer</v-icon>
          ใบเสร็จ (ย่อ)
        </v-btn>
        <v-btn
          color="error"
          @click="cancelListFunc()"
        >
          <v-icon class="mr-3">mdi-close-circle</v-icon>
          ยกเลิก
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import swal from 'sweetalert'

export default {
  data: () => ({
    paramsData: {},
    customerData: {},
    TreatmentData: {},
    loaddataTreatment: false,
    dataTreatment: [{
      _id: '000',
      no: 1,
      treatment_code: 'E0001',
      treatment_name: 'Botox กราม+เหนียง+หน้าไม่จำกัดยูนิต+ย้ำฟรี+หน้าใส 2 เข็ม',
      treatment_amount: 1,
      treatment_costprice: 4999
    }, {
      _id: '001',
      no: 2,
      treatment_code: 'E0002',
      treatment_name: 'ไหมทอร์นาโด 4 เส้น',
      treatment_amount: 1,
      treatment_costprice: 3999
    }, {
      _id: '002',
      no: 3,
      treatment_code: 'E0003',
      treatment_name: 'เข็มทู่ 4*50',
      treatment_amount: 1,
      treatment_costprice: 200
    }, {
      _id: '003',
      no: 4,
      treatment_code: 'E0004',
      treatment_name: 'ยาพารา 1*50',
      treatment_amount: 1,
      treatment_costprice: 50
    }, {
      _id: '004',
      no: 5,
      treatment_code: 'E0005',
      treatment_name: 'ยาลดบวม 1*50',
      treatment_amount: 1,
      treatment_costprice: 50
    }],
    itemsPerPageTreatment: 5,
    loaddataList: false,
    Summary: {},
    itemsList: [],
    dataList: [],
    itemsPerPageList: 5,
    totalPrice: 0,
    prepaidPrice: 0,
    discountPrice: 0,
    afterDiscountPrice: 0,
    vatPrice: {
      haveVat: false,
      noVat: true,
      price: 0
    },
    totalPoint: {
      value: 0,
      touse: false,
      use: 0
    },
    totalDiscountPoint: {
      value: 0,
      touse: false,
      use: 0
    },
    netPrice: 0,
    totalPayPrice: 0,
    canaddtoeditcourselist: true,
    bill_no: '',
    paymentItems: [{
      _id: '000',
      name: 'บัตรเครดิต',
      value: 'credit'
    }, {
      _id: '001',
      name: 'เงินสด',
      value: 'cash'
    }],
    paymentTypeItems: [{
      _id: '000',
      name: 'ผ่อนชำระ',
      value: 'ผ่อนชำระ'
    }, {
      _id: '001',
      name: 'ชำระเต็ม',
      value: 'ชำระเต็ม'
    }],
    payInThisTime: 0,
    vatPayInThisTime: {
      have: true,
      value: 0
    }
  }),
  computed: {
    headersTreatment () {
      return [
        {
          text: '',
          align: 'center',
          value: 'no',
          sortable: false
        }, {
          text: 'ข้อมูลรายการ',
          align: 'center',
          value: 'treatment_name',
          sortable: false
        }, {
          text: 'ราคา',
          align: 'center',
          value: 'treatment_costprice',
          sortable: false
        }
      ]
    },
    headersList () {
      return [{
        text: '',
        align: 'center',
        value: 'no',
        sortable: false
      }, {
        text: 'รหัสคอร์ส',
        align: 'center',
        value: 'list_code',
        sortable: false
      }, {
        text: 'ชื่อคอร์ส/หัตถการ',
        align: 'center',
        value: 'list_name',
        sortable: false
      }, {
        text: 'จำนวนครั้ง',
        align: 'center',
        value: 'list_amount',
        sortable: false
      }, {
        text: 'ราคา',
        align: 'center',
        value: 'list_costprice',
        sortable: false
      }, {
        text: '',
        align: 'center',
        value: 'actions',
        sortable: false
      }]
    }
  },
  created () {
    const self = this
    self.initData()
  },
  watch: {
    prepaidPrice () {
      const self = this
      self.computedNetPrice()
    },
    discountPrice () {
      const self = this
      self.computedNetPrice()
    },
    'vatPrice.price' () {
      const self = this
      self.computedNetPrice()
    }
  },
  methods: {
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    initData () {
      const self = this
      self.paramsData = self.$route.params.dataItems.paramsData
      self.customerData = self.$route.params.dataItems.paramsData.bill_customer || {}
      self.bill_no = self.$route.params.dataItems.paramsData.bill_no || '-'
      self.totalPrice = self.$route.params.dataItems.totalPrice || 0
      self.prepaidPrice = self.$route.params.dataItems.prepaidPrice || 0
      self.discountPrice = self.$route.params.dataItems.discountPrice || 0
      self.afterDiscountPrice = self.$route.params.dataItems.afterDiscountPrice || 0
      self.vatPrice = self.$route.params.dataItems.vatPrice || { haveVat: false, noVat: true, price: 0 }
      self.totalPoint = self.$route.params.dataItems.totalPoint || { value: 0, touse: false, use: 0 }
      self.totalDiscountPoint = self.$route.params.dataItems.totalDiscountPoint || { value: 0, touse: false, use: 0 }
      self.netPrice = self.$route.params.dataItems.netPrice || 0
      self.totalPayPrice = self.$route.params.dataItems.totalPayPrice || 0
      self.payInThisTime = self.$route.params.dataItems.payInThisTime || 0
      self.vatPayInThisTime = self.$route.params.dataItems.vatPayInThisTime || { have: true, value: 0 }
    },
    totalListItemPrice () {
      const self = this
      var sum = 0
      sum = self.dataList.reduce((a, b) => {
        return (a || 0) + (b.list_costprice || 0)
      }, 0)
      self.totalPrice = sum
      self.prepaidPrice = 0
      self.discountPrice = 98
      self.afterDiscountPrice = self.totalPrice - self.prepaidPrice - self.discountPrice
      self.netPrice = self.totalPrice - self.prepaidPrice - self.discountPrice - self.vatPrice.price
      self.totalPoint.value = 100
      self.totalDiscountPoint.value = 100
    },
    pointFunc () {
      const self = this
      if (self.totalPoint.touse) {
        if (self.totalPoint.use > self.totalPoint.value) {
          self.totalPoint.use = self.totalPoint.value
        }
      } else self.totalPoint.use = 0
    },
    pointDiscountFunc () {
      const self = this
      if (self.totalDiscountPoint.touse) {
        if (self.totalDiscountPoint.use > self.totalDiscountPoint.value) {
          self.totalDiscountPoint.use = self.totalDiscountPoint.value
        }
      } else self.totalDiscountPoint.use = 0
    },
    totalPayPriceFunc () {
      const self = this
      var sum = self.netPrice
      if (self.totalPoint.touse && self.totalDiscountPoint.touse) self.totalPayPrice = sum - self.totalPoint.use - self.totalDiscountPoint.use
      else if (self.totalPoint.touse) self.totalPayPrice = sum - self.totalPoint.use
      else if (self.totalDiscountPoint.touse) self.totalPayPrice = sum - self.totalDiscountPoint.use
      else self.totalPayPrice = self.netPrice
      return self.formatThaiBaht(self.totalPayPrice)
    },
    calVatPayInThisTime () {
      const self = this
      var sum = 0
      if (self.vatPayInThisTime.have) {
        self.vatPayInThisTime.value = self.payInThisTime * 0.03 || 0
        sum = self.vatPayInThisTime.value
      } else {
        self.vatPayInThisTime.value = 0
        sum = 0
      }
      return self.formatThaiBaht(sum)
    },
    calPayInThisTime () {
      const self = this
      var sum = 0
      if (self.payInThisTime) {
        sum = Number(self.payInThisTime)
      } else {
        sum = 0
      }
      return self.formatThaiBaht(sum)
    },
    calRemainPaid () {
      const self = this
      var sum = 0
      sum = self.totalPayPrice - self.payInThisTime
      return self.formatThaiBaht(sum)
    },
    computedNetPrice () {
      const self = this
      self.afterDiscountPrice = self.totalPrice - self.prepaidPrice - self.discountPrice
      self.netPrice = self.totalPrice - self.prepaidPrice - self.discountPrice - self.vatPrice.price
    },
    addListFunc () {
      swal('สำเร็จ', 'สร้างใบเสร็จสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    },
    cancelListFunc () {
      swal('สำเร็จ', 'ยกเลิกรายการสำเร็จ', 'success', {
        button: false,
        timer: 2000
      })
    }
  }
}
</script>

<style scoped>
@import "../../../styles/DrugsAndMedical_AddStock.css";
@import "../../../styles/Service.css";
</style>
